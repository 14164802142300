// Ticket: https://trello.com/c/HHc35vOG/697-implementing-automatic-word-highlighting-rectangle-shrink-feature-highlight

import Polygon from '../lib/polygon'

const THRESHOLD_GREY_COLOR = 100;
const SHRINK_MARGIN = 5
// Shrinks the drawn rectangle around the text
export default class RectangleShrinker {  
  
  constructor(context, path) {
    this.context = context
    this.path    = path

    this.polygon     = new Polygon(this.path)
    // These points carry values in hashes(by reference). Changing these values updates the polygon. We are using pass by reference to update the polygon
    this.topLeft     = this.polygon.topLeft()
    this.topRight    = this.polygon.topRight()
    this.bottomLeft  = this.polygon.bottomLeft();
    this.bottomRight = this.polygon.bottomRight();

    this.transformedTopLeft     = this.context.transformFromOriginalCoordinates(this.topLeft)
    this.transformedTopRight    = this.context.transformFromOriginalCoordinates(this.topRight)
    this.transformedBottomLeft  = this.context.transformFromOriginalCoordinates(this.bottomLeft)
    this.transformedBottomRight = this.context.transformFromOriginalCoordinates(this.bottomRight)
  }

  shrinkRectangle() {
    this.contractTopSide()
    this.contractBottomSide()
    this.contractLeftSide()
    this.contractRightSide()
  }

  contractTopSide(){
    let width  = this.transformedBottomRight.x - this.transformedTopLeft.x;
    
    var transformedTopY = this.transformedTopLeft.y
    while(transformedTopY < this.transformedBottomRight.y){
      let imageData = this.context.getImageData(this.transformedTopLeft.x, transformedTopY, width, 1);
      if (this.findDarkPixel(imageData.data, THRESHOLD_GREY_COLOR)) {
        this.topLeft.y = this.context.transformToOriginalCoordinates({ 'x': this.transformedTopLeft.x, 'y': transformedTopY - SHRINK_MARGIN}).y;
        this.topRight.y = this.context.transformToOriginalCoordinates({ 'x': this.transformedTopRight.x, 'y': transformedTopY - SHRINK_MARGIN}).y;
        break;
      } else {
        transformedTopY += 1;
      }
    }
  }

  contractBottomSide(){
    let width  = this.transformedBottomRight.x - this.transformedTopLeft.x;

    var transformedBottomY = this.transformedBottomLeft.y
    while(transformedBottomY > this.transformedTopRight.y){
      const imageData = this.context.getImageData(this.transformedBottomLeft.x, transformedBottomY, width, 1);
      if (this.findDarkPixel(imageData.data, THRESHOLD_GREY_COLOR)) {
        this.bottomLeft.y = this.context.transformToOriginalCoordinates({ 'x': this.transformedBottomLeft.x, 'y': transformedBottomY + SHRINK_MARGIN }).y;
        this.bottomRight.y = this.context.transformToOriginalCoordinates({ 'x': this.transformedBottomRight.x, 'y': transformedBottomY + SHRINK_MARGIN }).y;
        break;
      } else {
        transformedBottomY -= 1;
      }
    }
  }

  contractLeftSide(){
    let height = this.transformedBottomLeft.y - this.transformedTopLeft.y;

    var transformedLeftX = this.transformedBottomLeft.x
    while(transformedLeftX < this.transformedTopRight.x){
      const imageData = this.context.getImageData(transformedLeftX, this.transformedTopLeft.y, 1, height);
      if (this.findDarkPixel(imageData.data, THRESHOLD_GREY_COLOR)) {
        this.topLeft.x = this.context.transformToOriginalCoordinates({ 'x': transformedLeftX - SHRINK_MARGIN, 'y': this.transformedTopLeft.y }).x;
        this.bottomLeft.x = this.context.transformToOriginalCoordinates({ 'x': transformedLeftX - SHRINK_MARGIN, 'y': this.transformedBottomLeft.y }).x;
        break;
      } else {
        transformedLeftX += 1;
      }
    }
  }

  contractRightSide(){
    let height = this.transformedBottomLeft.y - this.transformedTopLeft.y;
    var transformedRightX = this.transformedBottomRight.x
    while(transformedRightX > this.transformedBottomLeft.x){
      const imageData = this.context.getImageData(transformedRightX - 1, this.transformedTopRight.y, 1, height);
      if (this.findDarkPixel(imageData.data, THRESHOLD_GREY_COLOR)) {
        this.topRight.x = this.context.transformToOriginalCoordinates({ 'x': transformedRightX + SHRINK_MARGIN, 'y': this.transformedTopRight.y }).x;
        this.bottomRight.x = this.context.transformToOriginalCoordinates({ 'x': transformedRightX + SHRINK_MARGIN, 'y': this.transformedBottomRight.y }).x;
        break;
      } else {
        transformedRightX -= 1;
      }
    }
  }

  // data is imageData here
  findDarkPixel(data){
    for (let i = 0; i < data.length; i += 4) {
      const intensity = (data[i] + data[i + 1] + data[i + 2]) / 3;
      if (intensity < THRESHOLD_GREY_COLOR) {
        return true; //found dark pixel on the line
      }
    }
    return false;
  }
};
