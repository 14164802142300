import Utils from '../utils'
import { distinct } from '../array_utils'
import Polygon from '../polygon'
import PolygonDivider from '../cropper/polygon_divider'
import * as turf from '@turf/turf'

// for the SLICE stage
// Handles the slices related functionalities -> works through context of canvas at the moment
const initSlicesManagement = (context) => {
  const FRACTION = 0.1
 
  context.slicingPaths = []

  // rawClip means clip data which is directly passed from backend
  context.prepareSlicesFromRawClips = async function(rawClips) {
    if (rawClips.length == 0){
      context.prepareFirstSliceForEmptyColumn();
      return;
    }

    const oldClips = [];
    for (let clip of rawClips) {
      // Todo: Make a clip js class
      oldClips.push({
        id: clip.id,
        polygon: context.unscaledPath(clip.polygon),
        scaledPolygon: clip.scaledPolygon,
        data: context.getClippedDataUrl(clip.polygon, context.parentImage),
      });
    }
    context.clips = oldClips;

    context.slicingPaths = context.transformClipsIntoSlices(rawClips).map(transformedRawClip => {
      return {
        id: transformedRawClip.id,
        path: context.lockSlicingPath(transformedRawClip.path),
        uniq_id: generateUniqIdentifier()
      }
    })

    // Added !isSnapshot check as for snapshot, prepareClipsFromSlices method was having issues for some cases and is not required for it
    if (!cropper.isSnapshot) {
      context.prepareClipsFromSlices()
    }

    // Debugging/Error Catching code starts here
    let foundDifferent = [false]; // Using array to pass by reference
    if (oldClips.length !== context.clips.length) {
      await confirmDiffSave(1, foundDifferent);
      return;
    }
    let index = 0;
    for (const oldClip of oldClips) {
      const newClip = context.clips[index];
      if (oldClip.scaledPolygon.length !== newClip.scaledPolygon.length) {

        console.log("Silenced Issue# 2")
        // await confirmDiffSave(2, foundDifferent);
        return;
      }
      let pointIndex = 0;
      for (const oldPoint of oldClip.scaledPolygon) {
        if (!newClip.scaledPolygon.some((point) => context.almostSame(oldPoint, point, 2)) && !foundDifferent[0]) {
          console.log("Silenced Issue# 3")
          //await confirmDiffSave(3, foundDifferent);
          return;
        }
        pointIndex++;
      }
      index++;
    }
  }

  // Looks like this method prepares "clips" from "slices" for the Slice stage
  context.prepareClipsFromSlices = () => {
    const clipsDataFromSlices = context.transformSlicingPathsToClipsData()

    // Weird checks - What's their reason
    if (clipsDataFromSlices.length) {
      context.clips = []
      for (const clipData of clipsDataFromSlices) {
        context.pushClippedData({ id: clipData.id, path: clipData.path, addOffset: false, slice_uniq_id: clipData.slice_uniq_id })
      }
    }
    // Sorting based on minimum Y to Order the regions in the column
    context.clips.sort((a, b) => {
      return Math.min.apply(Math, a.polygon.map((p) => p.y)) - Math.min.apply(Math, b.polygon.map((p) => p.y))
    })
  }

  // This generates clips data (including coordinates) from slices
  // Consider it as helper/private method for prepareClipsFromSlices
  context.transformSlicingPathsToClipsData = () => {
    let clipsData = []
    let scaledPolygon = distinct(context.unscaledPath(context.parentPolygon), ['x', 'y'])

    context.sortSlices()

    // Parent polygon is being divided here into multiple clips/polygons
    let polygonToSplit = scaledPolygon;
    context.slicingPaths.forEach((slicingPath, index) => {
      let splittedPolygons = PolygonDivider.split(polygonToSplit, slicingPath.path)

      let iterations = 20
      let iternation_number = 0
      while (splittedPolygons.length < 2 && iternation_number < iterations){
        iternation_number += 1

        // Hack to allow existing/corner points. Do this for existing slices only. Make new slices error free
        extendLine(slicingPath.path)
        splittedPolygons = PolygonDivider.split(polygonToSplit, slicingPath.path)
      }

      let clippedPolygon = splittedPolygons[0]
      polygonToSplit = splittedPolygons[1]

      clipsData.push({ id: slicingPath.id, path: clippedPolygon, slice_uniq_id: slicingPath.uniq_id })
    })
    return clipsData
  }
  
  context.prepareFirstSliceForEmptyColumn = () => {
    // find polygon's lowest point
    // find lowest from the two consecutive point of the lowest point
    // make a slice from both those points
    let polygon = new Polygon(context.parentPolygon)
    let lowestPoint = polygon.maxYPoint();

    let indexOfLowest = polygon.points.indexOf(lowestPoint)
    let prevPoint = polygon.points[(indexOfLowest - 1) % polygon.points.length]
    let nextPoint = polygon.points[(indexOfLowest + 1) % polygon.points.length]

    let lowerConsecutivePoint = (prevPoint.y > nextPoint.y) ? prevPoint : nextPoint;

    context.slicingPaths.push({
      id: null,
      path: context.unscaledPath([lowestPoint, lowerConsecutivePoint]),
      uniq_id: generateUniqIdentifier()
    })
  }

  // It makes slices from clips - when data gets loaded from backend in Javascript
  context.transformClipsIntoSlices = (clips) => {
    let extractedSlices = []

    // lowestPoint and lowerConsecutivePoint points are required because these are allowed edges of parent polygons in slices
    let polygon = new Polygon(context.parentPolygon)
    let lowestPoint = polygon.maxYPoint();

    let indexOfLowest = polygon.points.indexOf(lowestPoint)
    let prevPoint = polygon.points[(indexOfLowest - 1) % polygon.points.length]
    let nextPoint = polygon.points[(indexOfLowest + 1) % polygon.points.length]

    let lowerConsecutivePoint = (prevPoint.y > nextPoint.y) ? prevPoint : nextPoint;

    clips.forEach((clip) => {
      const extractedSlice = []
      clip.polygon.forEach((point) => {
        // Avoiding slices from the top edges of parent polygon
        const pointCoincidesWithParentTop = context.parentPolygon.find((polPoint) => {
          return context.almostSame(polPoint, point, FRACTION / 2) && !context.almostSame(lowestPoint, polPoint, FRACTION / 2) && !context.almostSame(lowerConsecutivePoint, polPoint, FRACTION / 2)
        })
        if (pointCoincidesWithParentTop) { return; }
        // Avoiding slices from existing slices points
        const pointCoincidesWithOtherSlices = extractedSlices.map((ep) => ep.path).flat().find(
          polPoint => context.almostSame(polPoint, point, FRACTION / 2)
        )
        if (pointCoincidesWithOtherSlices) { return; }

        extractedSlice.push(Utils.copyObject(point));
      })
      // Slice to have more than 1 points
      if (extractedSlice.length > 1) {
        extractedSlices.push({ id: clip.id, path: extractedSlice });
      }
    })
    extractedSlices = extractedSlices.map((ep) => {
      return {
        id: ep.id,
        path: context.unscaledPath(ep.path)
      }
    })

    return extractedSlices
  } 

  // Most of the cases for us, line_1 is polygon
  context.findIntersectingPoints = (line_1, line_2, is_line1_polygon = true) => {
    let line_1_temp = _.cloneDeep(line_1)
    if(is_line1_polygon){
      line_1_temp.push(line_1_temp[0])
    }

    let line_1_points = line_1_temp.map((point) => geometrySupportedPoint(point))
    let line_2_points = line_2.map((point) => geometrySupportedPoint(point))
    
    let line1 = turf.lineString(line_1_points);
    let line2 = turf.lineString(line_2_points);

    let intersectingPoints = turf.lineIntersect(line1, line2);

    let buildlqIntersectingPoints = []
    if(intersectingPoints && intersectingPoints.features.length > 0){
      buildlqIntersectingPoints = intersectingPoints.features.map((geometricalItem, index) => {
        return { point: buildlqPoint(geometricalItem), index: index }
      })
    }

    return buildlqIntersectingPoints;
  }

  // Function to extend line, with same slope obviously
  function extendedPoint(pointA, pointB, requiredDistance) {
    var slope = (pointB.y - pointA.y) / (pointB.x - pointA.x)
    // https://gis.stackexchange.com/questions/268422/extending-line-by-specified-distance-in-arcgis-javascript
    var r    = Math.sqrt(1 + Math.pow(slope, 2));
    var newX = pointA.x + (requiredDistance / r);
    var newY = pointA.y + ((requiredDistance * slope) / r);

    // var requiredYAttribute = pointA.y + (requiredXAttribute - pointA.x) * slope
    return { x: newX, y: newY }
  }

  // Extending line to handle minor differences in edge points - lines/polygons not intersecting
  function extendLine(linePath){
    makePathLtr(linePath)

    let leftEdge = linePath[0]
    let rightEdge = linePath[linePath.length - 1]

    let extendLineBy = (context.imageCanvasWidth() * 0.01)

    let newLeftEdge  = extendedPoint(leftEdge, linePath[1], -extendLineBy)
    let newRightEdge = extendedPoint(rightEdge, linePath[linePath.length - 2], extendLineBy)

    linePath[0] = newLeftEdge
    linePath[linePath.length - 1] = newRightEdge

    // linePath[0].x -= 1
    // linePath[linePath.length - 1].x += 1

    return linePath
  }

  // TODO: Helps in creation of a new Slice. Needs to be refactored 
  context.cutSlicingPathInsidePolygon = (unscaledPolygon, linePath) => {
    let updatedPath = []
    let edgePoints = context.findIntersectingPoints(unscaledPolygon, linePath)
    
    let iterations = 20
    let iternation_number = 0
    while (edgePoints.length < 2 && iternation_number < iterations){
      iternation_number += 1

      // Hack to allow existing/corner points. Do this for existing slices only. Make new slices error free
      extendLine(linePath)
      edgePoints = context.findIntersectingPoints(unscaledPolygon, linePath)
    }
    if (edgePoints.length < 2){
      return updatedPath 
    }

    if (edgePoints[0].point.x > edgePoints[edgePoints.length - 1].point.x)
      edgePoints.reverse()

    // Add the corner points from intersection, and keep the intermediate points
    updatedPath.push(edgePoints[0].point) //.push(edgePoints[0])
    let intermediatePoints = _.cloneDeep(linePath).slice(1, -1)
    updatedPath = updatedPath.concat(intermediatePoints)
    updatedPath.push(edgePoints[edgePoints.length - 1].point)
    return updatedPath
  }

  context.almostSame = (p1, p2, fraction = 2 * FRACTION, scale = false) => {
    if (scale) {
      p1 = context.scaledPoint(p1);
      p2 = context.scaledPoint(p2);
    }
    return Math.abs(+(+p1.x).toFixed(2) - +(+p2.x).toFixed(2)) <= fraction
      && Math.abs(+(+p1.y).toFixed(2) - +(+p2.y).toFixed(2)) <= fraction
  }

  // It creates a new slicingPath from the current path or from the path provided
  context.lockSlicingPath = (path = null) => {
    let usedCurrentPath = false
    if(path == null){
      usedCurrentPath = true
      path = context.currentPath
    }

    let copiedSlicePath = _.cloneDeep(path)
    makePathLtr(path)
    
    // check copiedSlicePath before and after
    let slicingPoints = context.cutSlicingPathInsidePolygon(context.unscaledPath(context.parentPolygon), path);
    if(slicingPoints.length < 1)
      return;

    let updatedPath = slicingPoints
    // updatedPath = context.scaledPath(updatedPath)

    if (usedCurrentPath) {
      context.slicingPaths.push({ path: Utils.copyObject(updatedPath), uniq_id: generateUniqIdentifier() })
      context.sortSlices()
      // context.currentPath = []
    }
    return updatedPath
  }

  context.sortSlices = () => {
    // Sorting based on minimum Y to Order the regions in the column
    context.slicingPaths = context.slicingPaths.sort((a, b) => {
      return Math.min.apply(Math, a.path.map((p) => p.y)) - Math.min.apply(Math, b.path.map((p) => p.y))
    })
  }

  function geometrySupportedPoint(point){
    return [point.x, point.y]
  }

  function buildlqPoint(geometryPoint){
    let coordinates = geometryPoint.geometry.coordinates
    return { x: coordinates[0], y: coordinates[1]} 
  }

  // Not being used. See better alternate findIntersectingPoints
  context.areLinesIntersecting = (line_1, line_2) => {
    pointA = geometrySupportedPoint(pointA)
    pointB = geometrySupportedPoint(pointB)
    pointY = geometrySupportedPoint(pointY)
    pointZ = geometrySupportedPoint(pointZ)

    let line1 = turf.lineString([pointA, pointB]);
    let line2 = turf.lineString([pointY, pointZ]);

    let intersectingPoints = turf.lineIntersect(line1, line2);
   
    if(intersectingPoints && intersectingPoints.features.length > 0){
      return buildlqPoint(intersectingPoints.features[0])  
    }
  }

  context.findRelatedSlicingPath = (clip) => {
    return context.slicingPaths.find((path) => path.uniq_id == clip.slice_uniq_id )
  }
}

const askForDeletionInsteadOfFix = () => {
  // Ask for deletion if fix request has been sent within 1 minute
  let askForDeletion = false
  let column_corruption = Cookies.get(`${cropper.firstCanvas().columnId}_corrupted_column_date`);
  if (column_corruption){
    let actionTime = new Date(Number(column_corruption)).getTime()
    let currentTime = new Date().getTime()

    if (currentTime - (60 * 1000) < actionTime)
      askForDeletion = true
  }
  return askForDeletion;
}

const confirmDiffSave = async (issueIdentifier = 0, foundDifferent) => {
  console.log("Issue: ", issueIdentifier) // Added for debugging
  foundDifferent[0] = true;

  if (askForDeletionInsteadOfFix()){
    if (confirm('It seems like column has been corrupted, do you want to DELETE the regions ? \n WARNING: \n *  This action is NOT reversable')){
      await API.post({
        url: `${window.location.pathname}/destroy_regions`,
        data: { },
      })
      window.location.reload();
    } else {
      cropper.setUnsavedChanges();
    }
  } else {
    if (confirm('It seems like column has been updated, do you want to update regions too? \n WARNING: \n * By updating regions, you will destroy all highlights made in this column! \n ** By updating regions, every regions that are not separated by full width line will be merged!')) {
      Cookies.set(`${cropper.firstCanvas().columnId}_corrupted_column_date`, new Date().getTime());
      await cropper.submitRegions();
      window.location.reload();
    } else {
      cropper.setUnsavedChanges();
    }
  }
}

// Determining from end points
// path is an array of points i.e [{x:0, y:1}, {x:1, y:2}]
function makePathLtr(path){
  if (path[0].x > path[path.length - 1].x)
    path.reverse()

  return path;
}

function makePathRtl(path){
  if (path[0].x < path[path.length - 1].x)
    path.reverse()

  return path;
}

export {
  initSlicesManagement
}
